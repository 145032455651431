<template>
  <div class="modal fade" :id="modalPayment" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">Link Telekonsul</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <div>
          <strong>
            Link Dokter : 
          </strong>
          <a class="text-break" :href="linkUrl" target="_blank">
            {{linkUrl}}
        </a>
        <div class="copy-link-position mb-2 pointer">
          <i class="la la-copy fs-22 px-3 pb-2" title="copy Link"  @click="copyCommand('linkUrl')"></i>
          <span class="position-absolute text-copy-link">
            Copy Link
          </span>
        </div>
      </div>
        <input type="hidden" id="linkUrlPasien" :value="detailPanel.linkPasien">
         <input type="hidden" id="linkUrl" :value="linkUrl">
         <div>
          <strong>Link Pasien :</strong> <a class="text-break" :href="detailPanel.linkPasien" target="_blank">
            {{detailPanel.linkPasien}}
        </a>
        <div class="copy-link-position pointer">
          <i class="la la-copy fs-22  px-3 pb-2" title="copy Link"  @click="copyCommand('linkUrlPasien')"></i>
          <span class="position-absolute text-copy-link">
            Copy Link
          </span>
        </div>
      </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-primary" @click="copyCommand('linkUrl')">Copy</button> -->
        <button type="button" class="btn btn-secondary" data-dismiss="modal"  @click="close()">Tutup</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { RepositoryFactory } from "./../../../../repositories/RepositoryFactory";
const TelekonsulRepository = RepositoryFactory.get("teleconsultResource");


export default {
    data() {
        return {
            linkUrl: ''
        }
    },
    props: {
        modalPayment: { type: String, default: "modalPayment" },
        value: Object
    },
    computed: {
        ...mapState('DayCalendarStore', {
          showModalTelekonsul: state => state.showModalTelekonsul,
          detailPanel: state => state.detailPanel
      }),
    },
    methods:{
        ...mapMutations({
                setShowModalLinktelekonsul:'DayCalendarStore/SET_SHOW_MODAL_LINKTELEKONSUL',
        }),
       close() {
            $("#" + this.modalPayment).modal("hide");
            this.setShowModalLinktelekonsul(false);
        },
        async convertURL() {
            const event = this.detailPanel
            let link = await TelekonsulRepository.generateLink()
            var baseUrl = link.data.split('?');
            let obj = Object.fromEntries(new URLSearchParams(baseUrl[1]));
            obj.gid = event.generatedId
            obj.did = event.dokterId
            obj.key = event.keyDokter
            obj.tenantId = event.tenantId

            let str = this.encodeUriParams(obj)
            const tujuan = `${baseUrl[0]}?${str}`
            // const tujuan = `https://dtelmed.cditdev.xyz/waitingRoom?${str}`
            return tujuan
        },
        copyCommand(element_id){
            let testingCodeToCopy = document.querySelector(`#${element_id}`)
            testingCodeToCopy.setAttribute('type', 'text')
            testingCodeToCopy.select()
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                 toastr.success('Berhasil disalin')
            } catch (err) {
                 toastr.error('Gagal disalin')
            }

            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
        encodeUriParams(obj) {
                var str = [];
                for (var p in obj) {
                    if (obj.hasOwnProperty(p)) {
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                }
                return str.join("&");
            },
    },
    async mounted () {
      $("#" + this.modalPayment).modal();
      this.wizard = new KTWizard("kt_wizard_v1", {
          startStep: 1,
      });
      this.linkUrl = await this.convertURL()
    },
}
</script>

<style>

</style>