<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Informasi Tambahan</h5>
        </div>
        <div class="modal-body">
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.medicalNo') }}:</label>
              <div class="col-lg-6">
                <input  
                  type="text"
                  class="form-control"
                  v-model="nomorMedicalRecord"
                  v-validate="'required'"
                  key="id"
                  data-vv-as="Nomor Medical Record"
                  name="noMr"
                  :class="{'is-invalid': errors.has('noMr') }"
                  placeholder="No Rekam Medik Pasien"
                />
                <div
                  v-show="errors.first('noMr')"
                  class="invalid-feedback"
                >{{ errors.first('noMr') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.nomorRegistrasi') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="nomorRegistrasi"
                  v-validate="'required'"
                  data-vv-as="Nomor Registrasi"
                  name="noRegistrasi"
                  :class="{'is-invalid': errors.has('noRegistrasi') }"
                  placeholder="No Registrasi "
                />
                <div
                  v-show="errors.first('noRegistrasi')"
                  class="invalid-feedback"
                >{{ errors.first('noRegistrasi') }}</div>
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-clean btn-bold btn-upper btn-font-md"
            @click="clearData()"
          >{{ $t("button.close") }}</button>
          <button
            type="button"
            class="btn btn-default btn-bold btn-upper btn-font-md"
            v-on:click="submitData()"
          >{{ $t("button.save") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ReservationRepository = RepositoryFactory.get("reservation");
const PatientRepository = RepositoryFactory.get("patient");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  data() {
    return {
      generatedId: null,
      nomorMedicalRecord: null,
      nomorRegistrasi: null,
      saveMr: true
    };
  },
  props: {
    modalName: { type: String , default:'ModalMrReg'},
    modalData: { type: Object }
  },
  methods: {
    updateData: function (reservation) {
      this.generatedId = reservation.generatedId
      this.nomorMedicalRecord = reservation.data.nomorMedicalRecord
      this.nomorRegistrasi = reservation.data.nomorRegistrasi
      this.saveMr = (this.nomorMedicalRecord == null || this.nomorMedicalRecord == '');
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
      this.$store.commit("DayCalendarStore/SET_SHOW_MODAL_MRREG", false)
    },
    submitData: function(){
        this.$validator.validateAll().then(result => {
            if (result) {
                var vx = this;
                let instance = new SweetAlert().showConfirm({
                    swalTitle: "Apakah Anda yakin?",
                    swalText: "Perubahan Data atau Status akan tersimpan.",
                    swalType: "info",
                    onConfirmButton: function () {
                        blockUI.blockModal();
                        vx.submit();
                    },
                    onCancelButton: function () {
                        blockUI.unblockModal();
                    }
                });
            }
        })
    },
    submit: function () {
        this.updatePasienArrive()
        // if(this.saveMr){
        //     this.updatePatient();
        // } else {
        //     this.updateRegistrasi();
        // }
    },
    successUpdate: function(){
        var vx = this;
        let instance = new SweetAlert().showInfo({
            swalTitle: "Berhasil!",
            swalText: "Perubahan data / status berhasil disimpan.",
            swalType: "success",
            onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
            }
        });
    },
    updatePasienArrive: function () {
      var payload = {
          generatedId: this.generatedId,
          mrn: this.nomorMedicalRecord,
          nomorRegistrasi: this.nomorRegistrasi,
      };
      ReservationRepository.updateArriveAppointment(payload)
        .then(response => {
            this.successUpdate();
        })
        .catch(error => {
            this.failedUpdate(error.response.data);
        });
    },
    // updateRegistrasi: function(){
    //     if(this.status != 'ARRIVED'){
    //         this.updateStatus();
    //     } else {
    //         this.updateNoReg();
    //     }
    // },
    // updateNoReg(){
    //     var vx = this;
    //     var payload = {
    //         nomorRegistrasi: this.nomorRegistrasi,
    //     };
    //     ReservationRepository.updateNomorRegistrasi(this.id,payload)
    //     .then(response => {
    //         vx.successUpdate();
    //     })
    //     .catch(error => {
    //         vx.failedUpdate(error.response.data);
    //     });
    // },
    // updateStatus(){
    //   var vx = this;
    //   var payload = {
    //       nomorRegistrasi: this.nomorRegistrasi,
    //   };
    //   ReservationRepository.updateArrived(this.id,payload)
    //     .then(response => {
    //         vx.successUpdate();
    //     })
    //     .catch(error => {
    //         vx.failedUpdate(error.response.data);
    //     });
    // },
    // updatePatient() {
    //   var vx = this;
    //   var payload = {
    //       id: this.patient.id,
    //       medicalNo: this.nomorMedicalRecord
    //   };
    //   PatientRepository.patchPatient(payload)
    //     .then(response => {
    //         vx.updateRegistrasi();
    //     })
    //     .catch(error => {
    //         vx.failedUpdate(error.response.data);
    //     });
    // },
    failedUpdate: function(error) {
        var message = (error.status == 400 && error.violations[0].field == 'nomor_registrasi') ?
        "Nomor registrasi "+this.nomorRegistrasi+" sudah terdaftar"
        :"Tidak dapat mengubah perubahan data / status."
        var vx = this;
        let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: message,
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
              vx.clearData();
            }
          });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.updateData(this.modalData);
    }
  }
};
</script>
